<template>
    <v-dialog v-model="dial" max-width="550" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-radio-group class="" v-model="medida_comprobante" row dense>
                    <v-radio label="A4" value="A4"></v-radio>
                    <v-radio label="80mm" value="80"></v-radio>
                    <v-radio label="58mm" value="58"></v-radio>
                </v-radio-group>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <div class="text--center">
                <v-radio-group class="mt-n2 mb-n3" v-model="descripcion" row dense>
                    <v-radio label="Larga" value="larga"></v-radio>
                    <v-radio label="Corta" value="corta"></v-radio>
                </v-radio-group>
            </div>
            <v-row dense>
                <v-col cols="6" xs="6" sm="3">
                    <v-card @click.prevent="verPDF('imprime')">
                        <v-container>
                            <v-img class="mx-auto" height="35" width="35" src="/disc.png"></v-img>
                            <h5 block class="text-center pa-1">IMPRIME</h5>
                        </v-container>
                    </v-card>
                </v-col>
                <v-col cols="6" xs="6" sm="3">
                    <v-card @click.prevent="(envia_what = true)">
                        <v-container>
                            <v-img class="mx-auto" height="35" width="35" src="/what.png"></v-img>
                            <h5 block class="text-center pa-1">WHATSAPP</h5>
                        </v-container>
                    </v-card>
                </v-col>
                <v-col cols="6" xs="6" sm="3">
                    <v-card @click.prevent="(envia_correo = true)">
                        <v-container>
                            <v-img class="mx-auto" height="35" width="35" src="/mail.png"></v-img>
                            <h5 block class="text-center pa-1">CORREO</h5>
                        </v-container>
                    </v-card>
                </v-col>
                <v-col cols="6" xs="6" sm="3">
                    <v-card @click.prevent="verPDF('descarga')">
                        <v-container>
                            <v-img class="mx-auto" height="35" width="35" src="/pdf.png"></v-img>
                            <h5 block class="text-center pa-1">DESCARGA</h5>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>

        <v-dialog v-model="envia_what" max-width="450">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="(envia_what = !envia_what)">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-5">
                <h4 class="text-center">Completa los Datos</h4>
                <h5 class="text-center">Este proceso generara un link para envio.</h5>
                <v-row dense class="mt-1">
                    <v-col cols="12">
                        <v-text-field append-icon="mdi-send-check" @click:append="envia_link_('w')" prepend-icon="+51"
                            dense outlined v-model="numero" type="number" label="Envio Whatsapp"
                            @keyup.enter="envia_link_('w')"></v-text-field>
                    </v-col>
                </v-row>
            </v-card>

        </v-dialog>
        <v-dialog v-model="envia_correo" max-width="500">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="(envia_correo = !envia_correo)">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-5">
                <h4 class="text-center">Completa los Datos</h4>
                <v-row dense class="mt-1">
                    <v-col cols="12">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row dense>
                                <v-col cols="7">
                                    <v-text-field style="font-size:90%;" autofocus dense outlined v-model="correo"
                                        label="Envio Correo"></v-text-field>
                                </v-col>
                                <v-col cols="5">
                                    <v-select style="font-size:75%;" :items="array_correos" label="CORREO" dense
                                        outlined v-model="correo_mail"></v-select>
                                </v-col>
                            </v-row>

                            <v-btn :disabled="!valid" color="info" class="mr-4" @click="envia_link_('c')">
                                ENVIAR CORREO
                            </v-btn>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card>

        </v-dialog>
        <v-dialog persistent v-model="progress" max-width="250">
            <v-card class="pa-12">
                <v-progress-linear indeterminate color="blue-grey" height="25">
                </v-progress-linear>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import {
    consultaDetalle,
    edita_campo_Cliente,
    busca_medicion,
    busca_receta_externa
} from '../../db'
import axios from "axios"
import {
    pdfGenera
} from '../../pdf_comprobantes'
import store from '@/store/index'
import moment from 'moment'
export default {
    name: 'caja',

    props: {
        data: [],
    },
    data() {
        return {
            dial: false,
            valid: true,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            progress: false,
            envia_what: false,
            envia_correo: false,
            correo: '',
            numero: '',
            seleccionado: '',
            datos_cliente: [],
            array_correos: ['@gmail.com', '@hotmail.com'],
            correo_mail: '@gmail.com',
            medida_comprobante: store.state.configImpresora.pdfInMM,
            descripcion: 'larga'
        }
    },
    created() {
        this.seleccionado = this.data
        if (this.seleccionado.dni != "00000000") {
            var cliente = store.state.clientes.find(id => String(id.documento) === String(this.seleccionado.dni))
            this.datos_cliente = cliente
            if (Boolean(cliente)) {
                this.numero = cliente.telefono
                this.correo = cliente.correo
            }
        }
        this.dial = true

    },
    methods: {
        async envia_link_(modo) {
            var item = this.seleccionado

            switch (modo) {
                case 'w':
                    if (this.numero != '' && String(this.numero).length == 9) {
                        store.commit("dialogoprogress", 1);
                        var blobs = 0
                        item = await this.consulta_recetas(item)
                        let snapshot = await consultaDetalle(item.numeracion).once("value")
                        var arraydatos = snapshot.val()
                        item.tamano = this.descripcion
                        blobs = await pdfGenera(arraydatos, item, this.medida_comprobante, 'sube')
                        blobs.name = this.seleccionado.numeracion
                        var url = await this.$store.dispatch("sube_archivo", { selectedFile: blobs })
                        await this.$store.dispatch("envio_sms", {
                            numero: this.numero,
                            url: url,
                        });
                        store.commit("dialogoprogress", 1);
                    } else {
                        store.commit('dialogosnackbar', 'Numero no es correcto!')
                    }
                    this.cierra()
                    break;
                case 'c':

                    if (this.datos_cliente != '') {
                        edita_campo_Cliente(this.datos_cliente.id, 'correo', this.correo)
                    }
                    this.progress = true
                    axios({
                        method: 'post',
                        url: 'https://us-central1-factura-peru.cloudfunctions.net/mailer',
                        headers: {},
                        data: {
                            "to": this.correo + this.correo_mail,
                            "subject": "Comprobante " + item.serie + '-' + item.correlativoDocEmitido,
                            "message": "Hola, tenemos un comprobante para ti",
                            "url_comprobante": 'https://opticentervision.pe/comprobantes_clientes/' + store.state.baseDatos.bd + '/' + store.state.baseDatos.ruc + '/' + item.cod_comprobante + '/' + item.serie + '/' + item.correlativoDocEmitido + '/' + item.dni,
                            "ruc_emisor": store.state.baseDatos.ruc,
                            "razon_social": store.state.baseDatos.name,
                            "fecha": moment.unix(item.fecha).format('YYYY-MM-DD'),
                            "correlativo": item.serie + '-' + item.correlativoDocEmitido
                        }
                    }).then(response =>
                        this.termina(response)
                    )
                    break;

            }
        },
        termina(a) {
            this.progress = false
            this.cierra()
        },
        async verPDF(modo) {
            var item = this.seleccionado
            this.progress = true
            var arraydatos = []
            item = await this.consulta_recetas(item)
            console.log(item.array_receta)
            let snapshot = await consultaDetalle(item.numeracion).once("value")
            arraydatos = snapshot.val()
            item.tamano = this.descripcion
            if (modo == 'descarga') {
                pdfGenera(arraydatos, item, this.medida_comprobante, 'descarga')
            }
            if (modo == 'imprime') {
                pdfGenera(arraydatos, item, this.medida_comprobante, 'abre')
            }
            this.progress = false
            this.cierra()

        },
        async consulta_recetas(item) {
            if (Boolean(item.id_receta)) {
                if (item.modo_receta == 'INTERNA') {
                    var snap = await busca_medicion(item.id_receta).once("value")
                    item.array_receta = snap.val()
                } else {
                    //  console.log(item, item.id_receta)
                    var snap = await busca_receta_externa(item.dni, item.id_receta).once("value")
                    console.log(snap.val())
                    if (snap.exists()) {
                        item.array_receta = snap.val()
                    }

                }
            }
            return item
        },

        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>
