<template>
    <div class="pa-2">
        <v-row class="mt-n1">
            <v-col cols="12">
                <v-row class="mt-n1 mb-2" dense>
                    <v-col cols="10">
                        <v-row class="mt-n4" dense>
                            <v-col cols="3">
                                <v-card @click.prevent="dial_monturas = true">
                                    <v-container>
                                        <v-img class="mx-auto" height="30" width="30" src="/monturas.png"></v-img>
                                        <h6 block class="text-center">MONTURAS</h6>
                                    </v-container>
                                </v-card>
                            </v-col>
                            <v-col cols="3">
                                <v-card @click.prevent="dial_accesorios = true">
                                    <v-container>
                                        <v-img class="mx-auto" height="30" width="30" src="/accesorios.png"></v-img>
                                        <h6 block class="text-center">ACCESORIOS</h6>
                                    </v-container>
                                </v-card>
                            </v-col>
                            <v-col cols="3">
                                <v-card @click.prevent="abre_productos()">
                                    <v-container>
                                        <v-img class="mx-auto" height="30" width="30" src="/producto.png"></v-img>
                                        <h6 block class="text-center">PRODUCTOS</h6>
                                    </v-container>
                                </v-card>
                            </v-col>
                            <v-col cols="3">
                                <v-card @click.prevent="abre_receta()">
                                    <v-container>
                                        <v-img class="mx-auto" height="30" width="30" src="/receta.png"></v-img>
                                        <h6 block class="text-center">RECETA</h6>
                                    </v-container>
                                </v-card>
                            </v-col>

                        </v-row>
                    </v-col>
                    <v-col cols="2">
                        <v-card class="mt-n3" @click.prevent="abre_parametros()">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/parametros.png"></v-img>
                                <h6 block class="text-center">PARAMETROS</h6>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>

                <v-card dense>
                    <v-tabs dense class="mb-2" v-model="tab" background-color="transparent" color="error" grow>
                        <v-tab>DATOS PACIENTE</v-tab>
                        <v-tab>DATOS FACTURA</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-card class="mt-n2 pa-2">
                                <v-row class="mt-n2">
                                    <v-col cols="3">
                                        <v-select disabled style="font-size:85%;" outlined dense v-model="documento"
                                            :items="documentos" menu-props="auto" hide-details
                                            label="Tipo Doc"></v-select>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field style="font-size:85%;" outlined type="number" dense
                                            v-model="numero" label="Numero" append-icon="mdi-magnify"
                                            @click:append="BuscarDocumento()"
                                            @keyup.enter="BuscarDocumento()"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field style="font-size:85%;" outlined dense v-model="nombreCompleto"
                                            label="Nombre" append-icon="mdi-eye"
                                            @click:append="abre_cliente()"></v-text-field>
                                    </v-col>

                                </v-row>

                                <v-row class="mt-n9 mb-n8">
                                    <v-col cols="6">
                                        <v-text-field style="font-size:85%;" outlined dense v-model="telefono"
                                            type="number" label="Telefono"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-textarea style="font-size:85%;" outlined dense v-model="observacion"
                                            auto-grow filled label="Comentario" rows="1"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card class="mt-n2 pa-2">
                                <v-row class="mt-n2">
                                    <v-col cols="3">
                                        <v-select style="font-size:85%;" outlined dense v-model="doc_fact" disabled
                                            :items="documentos" menu-props="auto" hide-details
                                            label="Tipo Doc"></v-select>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field style="font-size:85%;" outlined type="number" dense
                                            v-model="num_fact" label="Numero" append-icon="mdi-magnify"
                                            @click:append="BuscarDocumento1()"
                                            @keyup.enter="BuscarDocumento1()"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field style="font-size:85%;" outlined dense v-model="nom_fact"
                                            label="Nombre" append-icon="mdi-eye"
                                            @click:append="abre_cliente()"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-n9 mb-n8">
                                    <v-col cols="6">
                                        <v-text-field style="font-size:85%;" outlined dense v-model="dir_fact"
                                            label="Direccion"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field style="font-size:85%;" outlined dense v-model="telf_fact"
                                            type="number" label="Telefono"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-n1">
            <v-col cols="8">
                <v-card>
                    <v-simple-table dense fixed-header height="45vh">
                        <template v-slot:default>

                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Descripcion
                                    </th>
                                    <th class="text-left">
                                        Precio
                                    </th>
                                    <th class="text-left">
                                        Cantidad
                                    </th>
                                    <th class="text-left">
                                        Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in listaproductos" :key="item.uuid"
                                    @click.prevent="editaProducto(item)">
                                    <td style="font-size:85%;" v-if="item.operacion == 'GRATUITA'">{{ item.nombre }} -
                                        <strong class="red--text">OBSEQUIO</strong>
                                        <strong class="red--text" v-if="item.od && !item.oi"> - OD</strong>
                                        <strong class="red--text" v-if="item.oi && !item.od"> - OI</strong>
                                    </td>
                                    <td style="font-size:85%;" v-else>{{ item.nombre }}
                                        <strong class="red--text" v-if="item.od && !item.oi"> - OD</strong>
                                        <strong class="red--text" v-if="item.oi && !item.od"> - OI</strong>
                                    </td>
                                    <td style="font-size:85%;">S/.{{ item.precio }}</td>
                                    <td style="font-size:85%;">{{ item.cantidad }}</td>
                                    <td style="font-size:85%;">S/{{ item.total }}</td>
                                </tr>
                            </tbody>
                        </template>

                    </v-simple-table>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="pa-3">
                    <v-row>
                        <v-col cols="12">
                            <v-select style="font-size:75%;" :items="$store.state.array_empleados" label="VENDEDOR"
                                dense outlined v-model="vendedor"></v-select>
                        </v-col>
                        <v-col cols="12" class="mt-n9">
                            <v-divider color="black"></v-divider>
                        </v-col>
                        <v-col cols="12" class="mt-n5">
                            <v-text-field prefix="S/." readonly outlined dense v-model="total" type="number"
                                label="Total"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-n10">
                            <v-text-field prefix="S/." outlined dense v-model="a_cuenta" type="number"
                                label="A Cuenta"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-n9">
                            <v-divider color="black"></v-divider>
                        </v-col>
                        <v-col cols="12" class="mt-n5">
                            <v-text-field prefix="S/." readonly outlined dense v-model="saldo" type="number"
                                label="Saldo"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n7" dense>
                        <v-col cols="12">
                            <v-card @click="abre_cobrar()"
                                :disabled="vendedor == '' || listaproductos == '' || a_cuenta == ''">
                                <v-container>
                                    <v-img class="mx-auto" height="40" width="40" src="/caja.png"></v-img>
                                    <h6 block class="text-center">COBRAR</h6>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dial_cobrar" max-width="590" transition="dialog-bottom-transition">
            <v-card class="pa-3">
                {{ valida_modo_pago }}
                <v-text-field type="date" outlined dense v-model="date" label="Emision"></v-text-field>
                <v-divider class="mt-n2 mb-7" color="black"></v-divider>
                <v-row class="mt-n4" dense v-for="item in modopagos" :key="item.modo">
                    <v-col cols="12">
                        <v-row dense no-gutters>
                            <v-col cols="2">
                                <v-img class="ml-2" height="35" width="35" :src="busca_ico(item.nombre)"></v-img>
                            </v-col>
                            <v-col cols="10">
                                <v-text-field class="ml-n8" :autofocus="item.nombre == 'EFECTIVO'" prefix="S/." outlined
                                    dense v-model="item.monto" type="number" :label="item.nombre"></v-text-field>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>
                <v-row class="mt-n2">
                    <v-col cols="4">
                        <v-btn :disabled="num_fact != ''" color="info" block
                            @click="genera_comprobante(1)">RECIBO</v-btn>
                    </v-col>
                    <v-col cols="4">
                        <v-btn :disabled="num_fact != ''" color="success" block
                            @click="genera_comprobante(2)">BOLETA</v-btn>
                    </v-col>
                    <v-col cols="4">
                        <v-btn :disabled="!es_factura" color="warning" block
                            @click="genera_comprobante(3)">FACTURA</v-btn>
                    </v-col>
                </v-row>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dialo_edita" max-width="390">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialo_edita = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-row class="mx-auto mt-4 text-center" dense v-if="false">
                    <v-col cols="4">
                        <v-icon @click="suma()" color="green">mdi-plus</v-icon>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field dense @keyup.enter="grabaEdita()" type="number" outlined v-model="cantidadEdita"
                            label="Cantidad"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-icon @click="resta()" color="red">mdi-minus</v-icon>
                    </v-col>
                </v-row>
                <v-row class="mx-auto text-center" dense>
                    <v-col cols="12">
                        <v-text-field disabled dense @keyup.enter="grabaEdita()" type="number" class="pa-3"
                            v-model="precioedita" label="Precio"></v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions class="mt-n6">
                    <v-btn color="red darken-1" text @click="eliminaedita()">
                        Elimina
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="grabaEdita()">
                        Graba
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_prod_param" max-width="550">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_prod_param = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-simple-table fixed-header height="40vh">
                    <template v-slot:default>

                        <thead>
                            <tr>
                                <th class="text-left">
                                    Descripcion
                                </th>
                                <th class="text-left">
                                    Parametro
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in lista_productos_parametro" :key="item.uuid"
                                @click.prevent="selecciona_parametro(item)">
                                <td style="font-size:85%;">{{ item.nombre }}</td>
                                <td style="font-size:90%;" v-if="item.id_parametro != undefined">
                                    <v-img class="mx-auto mt-n2" height="35" width="35" src="/chek.gif"></v-img>
                                </td>
                                <td style="font-size:90%;" v-else>
                                    <v-img class="mx-auto mt-n2" height="35" width="35" src="/duda.gif"></v-img>
                                </td>
                            </tr>
                        </tbody>
                    </template>

                </v-simple-table>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialo_cantidad" max-width="450px">
            <v-card class="pa-3 mx-auto text-center">
                <v-row class="mt-n2 mb-n1 mx-auto text-center" style="text-align:center" dense>
                    <v-col cols="6">
                        <v-checkbox v-model="od" label="O.D."></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox v-model="oi" label="O.I."></v-checkbox>
                    </v-col>
                </v-row>
                {{ calcula_precio }}
                <v-text-field type="number" autofocus outlined dense v-model="precio_edita" label="PRECIO"
                    @focus="$event.target.select()"></v-text-field>
                <v-text-field type="number" outlined dense v-model="dias_entrega" label="Dias de entrega"
                    @focus="$event.target.select()"></v-text-field>

                <v-card @click="agregaCatalogo()">
                    <v-container>
                        <v-img class="mx-auto" height="40" width="40" src="/doctor.png"></v-img>
                        <h6 block class="text-center">Seleccionar</h6>
                    </v-container>
                </v-card>
            </v-card>
        </v-dialog>
        <clientes :dial_L="dial_clientes" @array="agregacliente($event)" @cierra="dial_clientes = false" />
        <lista_monturas v-if="dial_monturas" @array="agrega_monturas($event)" @cierra="dial_monturas = false" />
        <lista_productos v-if="dial_productos" :dial="dial_productos" @array="agrega_productos($event)"
            @cierra="dial_productos = false" :edicion="true" :serie="serie_receta" />
        <lista_accesorios v-if="dial_accesorios" @array="agrega_accesorios($event)" @cierra="dial_accesorios = false" />
        <nuevo_cli v-if="dial_paciente" :num_cliente="numero" :dial_cliente="dial_paciente"
            @cierra="dial_paciente = $event" @cliente="dial_paciente = false" />
        <receta v-if="dial_receta" :data="data_receta" @cierra="dial_receta = false"
            @recomienda="recomendacion($event)" />
        <agrega_receta v-if="dial_agrega_receta" :data="data_receta" @cierra="dial_agrega_receta = false" />
        <parametros v-if="dial_parametros" @cierra="dial_parametros = false" :cliente="cliente_parametro"
            :productos="producto_parametro" @guarda="guarda_parametro($event)" :parametro="parametro_medido"
            :editar="true" />
        <imprime v-if="genera_pdf" :data="seleccionado" @cierra="limpiacajas(), genera_pdf = false" />

        <lector v-if="$store.state.abre_qr" msg="Hello Vue in CodeSandbox!" @resultado="montura_qr($event)" />

    </div>
</template>

<script>
import imprime from '@/components/dialogos/dialog_imprime'
import {
    buscaCliente,
    busca_parametros,
    edita_medicion,
    nuevoflujo,
    editaMontura,
    editaProformas,
    nuevo_medicion,
    nuevoCliente,
    allcitas,
    edita_campo_Cliente
} from '../../db'
import {
    cobrar_js
} from '../../genera_comprobante'
import {
    send_sms
} from '../../envio_sms'
import {
    genera_pedidos
} from '../../genera_pedido'
import {
    obten_contador_externo,
    sumarCorrelativo_externo
} from '../../contadores'
import moment from 'moment'
import store from '@/store/index'
import axios from "axios"
import clientes from '@/components/dialogos/dialogoClientes'
import lista_monturas from '@/components/almacen/lista_monturas'
import lista_productos from '@/components/almacen/lista_productos'
import lista_accesorios from '@/components/almacen/lista_accesorios'
import receta from '@/components/dialogos/dialog_receta'
import agrega_receta from '@/components/dialogos/dialog_agre_receta'
import parametros from '@/components/dialogos/dialog_parametros'
import nuevo_cli from '@/views/optometria/busca_cliente'
import lector from "@/components/lector";
export default {
    name: 'caja',

    components: {
        clientes,
        lista_monturas,
        lista_productos,
        lista_accesorios,
        nuevo_cli,
        receta,
        agrega_receta,
        parametros,
        imprime,
        lector
    },
    data() {
        return {
            genera_pdf: false,
            seleccionado: '',
            tab: null,
            date: moment(String(new Date)).format('YYYY-MM-DD'),
            dial_parametros: false,
            dial_clientes: false,
            dial_monturas: false,
            dial_productos: false,
            dial_accesorios: false,
            dial_cobrar: false,
            dial_paciente: false,
            dial_receta: false,
            dial_agrega_receta: false,
            dialo_edita: false,
            dial_prod_param: false,
            documentos: [
                'DNI',
                'RUC',
                'Pasaporte',
                'Carnet de Extranjeria'
            ],
            modopagos: [{
                nombre: 'EFECTIVO',
                monto: 0
            }],
            documento: 'DNI',
            numero: '',
            nombreCompleto: '',
            direccion: '',
            telefono: '',
            observacion: '',
            lista_venta: [],
            data_receta: '',
            vendedor: '',
            total: 0,
            a_cuenta: '',
            saldo: 0,
            doc_fact: 'RUC',
            num_fact: '',
            nom_fact: '',
            dir_fact: '',
            telf_fact: '',
            codigoedita: '',
            cantidadEdita: '',
            precioedita: '',
            item_edita: '',
            producto_parametro: [],
            es_factura: false,
            array_parametro: [],
            parametro_medido: [],
            cliente_parametro: [],
            lista_productos_parametro: [],
            dialo_cantidad: false,
            item_selecto: [],
            precio: '',
            od: true,
            oi: true,
            dias_entrega: 7,
            array_proforma: [],
            serie_receta: '',
            precio_edita: ''
        }
    },
    created() {
        this.carga_proforma()
        this.modopagos = []
        var modos = store.state.tipo_pago
        for (var i = 0; i < modos.length; i++) {
            var data = modos[i]
            var mont = ''
            if (this.a_cuenta != 0) {
                if (data == 'EFECTIVO') {
                    mont = this.a_cuenta
                }
            } else {
                if (data == 'EFECTIVO') {
                    mont = this.saldo
                }
            }
            this.modopagos.push({
                nombre: data,
                monto: mont
            })
        }
    },
    mounted() {
        store.commit('activa_pagos', true)
    },
    beforeDestroy() {
        store.commit('array_proforma', '')
        store.commit('activa_pagos', false)
    },
    computed: {
        listaproductos() {
            var suma = 0
            for (var i = 0; i < this.lista_venta.length; i++) {
                suma = suma + (this.lista_venta[i].cantidad * this.lista_venta[i].precio)
            }
            this.total = suma
            this.saldo = this.total - this.a_cuenta
            if (store.state.array_paciente != '') {
                this.numero = store.state.array_paciente.dni_cliente
                this.nombreCompleto = store.state.array_paciente.nom_cliente
                this.data_receta = store.state.array_paciente
                this.serie_receta = this.data_receta.serie
                if (store.state.clientes.find(id => String(id.id) === String(store.state.array_paciente.dni_cliente)) != undefined) {
                    // console.log()
                    if (store.state.clientes.find(id => String(id.id) === String(store.state.array_paciente.dni_cliente)).telefono != '') {
                        this.telefono = store.state.clientes.find(id => String(id.id) === String(store.state.array_paciente.dni_cliente)).telefono
                    }

                }

            }
            return this.lista_venta
        },
        valida_modo_pago() {
            var result = false
            var a = this.modopagos.find(id => String(id.nombre) == String('EFECTIVO'))
            if (this.a_cuenta != 0) {
                if (a.monto == this.a_cuenta) {
                    result = true
                }
            } else {
                if (a.monto == this.saldo) {
                    result = true
                }
            }
            if (this.a_cuenta != 0) {
                var resta = this.a_cuenta - this.modopagos[1].monto -
                    this.modopagos[2].monto - this.modopagos[3].monto - this.modopagos[4].monto
                if (resta < 0) {
                    store.commit('dialogosnackbar', 'MONTOS NO COINCIDEN')
                } else {
                    this.modopagos[0].monto = resta
                }

            } else {
                var resta = this.saldo - this.modopagos[1].monto -
                    this.modopagos[2].monto - this.modopagos[3].monto - this.modopagos[4].monto
                if (resta < 0) {
                    store.commit('dialogosnackbar', 'MONTOS NO COINCIDEN')
                } else {
                    this.modopagos[0].monto = resta
                }
            }
            return result
        },
        calcula_precio() {

            var precio = this.item_selecto.precio
            if (this.od && !this.oi) {
                precio = (precio / 2).toFixed(2)
            }
            if (!this.od && this.oi) {
                precio = (precio / 2).toFixed(2)
            }
            this.precio_edita = precio
            return ''
        }
    },

    methods: {
        async completa_dni() {
            if (this.numero == '') {
                store.commit("dialogoprogress", 1)
                this.numero = await obten_contador_externo("orden_pacientes")
                await sumarCorrelativo_externo("orden_pacientes", this.numero)
                store.commit("dialogoprogress", 1)
            }
            return true
        },
        async abre_productos() {
            if (this.data_receta == '') {
                alert('Ingrese Receta Valida')
                return
            }
            await this.completa_dni()
            this.dial_productos = true
        },
        recomendacion(item) {
            this.oi = true
            this.od = true
            this.precio = item.precio
            this.precio_edita = item.precio
            this.cantidad = 1
            this.item_selecto = item
            this.dialo_cantidad = true
        },
        agregaCatalogo() {
            var item = this.item_selecto
            if (parseFloat(item.costo) > parseFloat(this.precio_edita)) {
                alert('PRECIO POR DEBAJO DEL LIMITE PERMITIDO')
                return
            }
            var precio = this.precio_edita
            var operacion = item.operacion
            var array = {
                id: item.id,
                id_pos: item.id_pos,
                marca: item.marca,
                descripcion: item.descripcion,
                cantidad: 1,
                precio: precio,
                operacion: operacion,
                tipoproducto: item.tipoproducto,
                tipo_focal: item.tipo_focal,
                od: this.od,
                oi: this.oi,
                dias_entrega: this.dias_entrega,
                costo: item.costo
            }
            this.agrega_productos(array)
            this.dialo_cantidad = false
            this.dial_receta = false
        },
        carga_proforma() {
            this.array_proforma = store.state.array_proforma
            if (this.array_proforma != '') {
                this.lista_venta = this.array_proforma.data
                this.vendedor = this.array_proforma.vendedor
            }
        },
        abre_parametros() {
            this.lista_productos_parametro = []
            var data = this.lista_venta
            var array = []
            for (var i = 0; i < data.length; i++) {
                var item = data[i]

                if (item.tipo_focal == 'MULTIFOCAL DIGITAL' || item.tipo_focal == "MONOFOCAL DIGITAL") {
                    array.push(item)
                }
            }
            if (array != '') {
                if (this.nombreCompleto == '') {
                    store.commit('dialogosnackbar', 'Ingrese Datos de Paciente')
                } else {
                    if (store.state.array_paciente.modo == 'EXTERNA') {
                        var especialista = 'RECETA EXTERNA'
                    } else {
                        var especialista = store.state.array_paciente.especialista
                    }
                    this.cliente_parametro = {
                        nom_cliente: this.nombreCompleto,
                        dni_cliente: this.numero,
                        especialista: especialista
                    }
                    this.lista_productos_parametro = array
                    this.dial_prod_param = true
                }
            } else {
                store.commit('dialogosnackbar', 'No existe Producto Valido')
            }

        },
        async selecciona_parametro(data) {
            this.producto_parametro = data
            if (Boolean(data.id_parametro)) {
                var snap = await busca_parametros(this.numero, data.id_parametro).once("value")
                this.parametro_medido = snap.val()
            } else {
                this.parametro_medido = undefined
            }
            this.dial_prod_param = false
            this.dial_parametros = true
        },
        guarda_parametro(data) {
            var pos = this.lista_venta.map(e => e.uuid).indexOf(this.producto_parametro.uuid)
            this.lista_venta[pos].id_parametro = data.id
            this.dial_parametros = false
        },
        editaProducto(id) {
            var i = this.listaproductos.map(e => e.uuid).indexOf(id.uuid)
            this.codigoedita = i
            this.cantidadEdita = this.listaproductos[i].cantidad
            this.precioedita = this.listaproductos[i].precio
            this.item_edita = this.listaproductos[i]
            this.dialo_edita = true
        },
        grabaEdita() {
            this.listaproductos[this.codigoedita].precio = this.precioedita
            this.listaproductos[this.codigoedita].total = this.precioedita * this.cantidadEdita
            this.dialo_edita = false
        },
        async eliminaedita() {
            /*if (this.item_edita.tipo == 'M') {
                await editaMontura(this.item_edita.id + '/stock', 1)
            }*/

            this.listaproductos.splice(this.codigoedita, 1)
            this.dialo_edita = false
        },
        valida_costo() {
            var suma = 0
            var array = this.listaproductos
            for (var i = 0; i < array.length; i++) {
                suma = suma + array[i].costo
            }
            return suma
        },
        abre_cobrar() {
            if (this.num_fact.length != 11 && this.num_fact != '') {
                alert('Factura no cumple con el estandar')
                return
            }
            if (this.valida_costo() > parseFloat(this.a_cuenta)) {

                if (confirm('El monto a cuenta no cubre los costos, desea de igual forma seguir con la venta?')) {

                } else {
                    return
                }
            }
            if (this.a_cuenta > this.total) {
                alert('MONTO NO PUEDE SER MAYOR QUE EL TOTAL')
                return
            }
            this.modopagos = []
            var modos = store.state.tipo_pago
            for (var i = 0; i < modos.length; i++) {
                var data = modos[i]
                var mont = ''
                if (this.a_cuenta != 0) {
                    if (data == 'EFECTIVO') {
                        mont = this.a_cuenta
                    }
                } else {
                    if (data == 'EFECTIVO') {
                        mont = this.saldo
                    }
                }
                this.modopagos.push({
                    nombre: data,
                    monto: mont
                })
            }
            this.es_factura = false
            if (this.num_fact != '' && this.nom_fact != '') {
                this.es_factura = true
            }
            if (this.a_cuenta == '') {
                this.a_cuenta = 0
            }
            this.dial_cobrar = true

        },
        async guarda_cliente() {
            var fecha = moment(String(this.date1)) / 1000
            var array = {
                activo: true,
                id: this.documento,
                tipodoc: this.tipodoc,
                documento: this.documento,
                nombre: this.nombre.toUpperCase(),
                correo: '',
                departamento: 'LA LIBERTAD',
                provincia: 'TRUJILLO',
                distrito: 'TRUJILLO',
                ubigeo: 130101,
                direccion: '',
                telefono: this.telefono,
                activa_fecha: true,
                nacimiento: fecha,
                edad: 0,
                tienda: store.state.permisos.tienda,
                es_menor: false
            }
            nuevoCliente(array.id, array)
        },
        async abre_receta() {
            await this.completa_dni()
            if (store.state.array_paciente != '') {
                this.dial_receta = true
            } else {
                if (this.nombreCompleto == '') {
                    store.commit('dialogosnackbar', 'Ingrese Datos de Paciente')
                } else {
                    this.data_receta = {
                        nom_cliente: this.nombreCompleto,
                        dni_cliente: this.numero
                    }
                    this.dial_agrega_receta = true
                }
            }
        },
        abre_cliente() {
            this.dial_paciente = true
        },
        BuscarDocumento() {
            if (this.numero.length == 11) {
                this.documento = "RUC"
            } else {
                this.documento = "DNI"
            }
            if (this.numero != '' && this.documento == "RUC" || this.documento == "DNI" &&
                this.numero.length == 8 || this.numero.length == 11) {
                store.commit("dialogoprogress", 1)

                buscaCliente(this.numero)
                    .once("value")
                    .then((snapshot) => {
                        if (snapshot.exists()) {
                            if (snapshot.val().nombre != undefined) {
                                console.log(snapshot.val().nombre)
                                this.nombreCompleto = snapshot.val().nombre
                                this.direccion = snapshot.val().direccion
                                this.telefono = snapshot.val().telefono
                                store.commit("dialogoprogress", 1)
                            } else {
                                this.consultaApiPeru()
                            }

                        } else {
                            this.consultaApiPeru()
                        }
                    })
            } else {
                this.dial_clientes = true
            }
        },
        consultaApiPeru() {
            var self = this,
                token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
            axios
                .get('https://apiperu.dev/api/' + this.documento.toLowerCase() + '/' + this.numero, {
                    headers: {
                        Content_Type: 'application/json',
                        authorization: ' Bearer ' + token
                    }
                })
                .then(response => (this.info = response.data,
                    store.commit("dialogoprogress", 1),
                    self.llenardatos(response.data.data)
                ))
                .catch(error => {
                    store.commit("dialogoprogress", 1),
                        store.commit('dialogosnackbar', 'Documento Invalido')
                })
        },
        llenardatos(data) {
            if (this.documento == 'DNI') {
                this.nombreCompleto = data.nombre_completo
                this.direccion = ''
                this.telfcliente = ''
                this.refcliente = ''
            }
            if (this.documento == 'RUC') {
                this.nombreCompleto = data.nombre_o_razon_social
                this.direccion = data.direccion_completa
                this.telfcliente = ''
                this.refcliente = ''
            }
        },
        agregacliente(item) {
            this.numero = item.documento
            this.nombreCompleto = item.nombre
            this.direccion = item.direccion
            this.documento = 'DNI'
            this.telefono = item.telefono
            this.dial_clientes = false
        },
        async agrega_monturas(item) {
            if (this.lista_venta.find(it => it.id == item.id)) {
                alert('MONTURA YA AGREGADA')
                return
            }
            store.commit("dialogoprogress", 1)
            this.lista_venta.push({
                uuid: this.create_UUID().substring(29),
                id: item.id,
                nombre: item.marca + ' ' + item.modelo,
                cantidad: item.cantidad,
                precio: item.precio,
                total: item.precio * item.cantidad,
                operacion: item.operacion,
                tipoproducto: item.tipoproducto,
                medida: 'UNIDAD',
                tipo: 'M',
                costo: item.costo,
            })
            //await editaMontura(item.id + '/stock', 0)
            setTimeout(() => store.commit("dialogoprogress", 1), 500);
        },
        agrega_accesorios(item) {
            store.commit("dialogoprogress", 1)
            this.lista_venta.push({
                uuid: this.create_UUID().substring(29),
                id: item.id,
                nombre: item.descripcion,
                cantidad: item.cantidad,
                precio: item.precio,
                total: item.precio * item.cantidad,
                operacion: item.operacion,
                tipoproducto: item.tipoproducto,
                medida: 'UNIDAD',
                tipo: 'A',
                costo: 0
            })
            setTimeout(() => store.commit("dialogoprogress", 1), 500);
        },
        agrega_productos(item) {
            console.log(item)
            var pos = ''
            if (item.id_pos != undefined) {
                pos = item.id_pos
            }
            var tt = 'P'
            if (item.tipo_focal == undefined) {
                item.tipo_focal = ''
                tt = 'L'
            }
            if (item.costo == undefined) {
                item.costo = 0
            }
            store.commit("dialogoprogress", 1)
            this.lista_venta.push({
                uuid: this.create_UUID().substring(29),
                id: item.id,
                id_pos: pos,
                nombre: item.marca + ' ' + item.descripcion,
                cantidad: item.cantidad,
                precio: item.precio,
                total: item.precio * item.cantidad,
                operacion: item.operacion,
                tipo_focal: item.tipo_focal,
                tipoproducto: item.tipoproducto,
                medida: 'UNIDAD',
                od: item.od,
                oi: item.oi,
                tipo: tt,
                dias_entrega: item.dias_entrega,
                costo: parseFloat(item.costo),

            })
            console.log(this.lista_venta)
            setTimeout(() => store.commit("dialogoprogress", 1), 500);
        },
        BuscarDocumento1() {
            if (this.num_fact.length == 11) {
                this.doc_fact = "RUC"
            } else {
                this.doc_fact = "DNI"
            }
            if (this.num_fact != '' && this.doc_fact == "RUC" || this.doc_fact == "DNI" &&
                this.num_fact.length == 8 || this.num_fact.length == 11) {
                store.commit("dialogoprogress", 1)
                this.consultaApiPeru1()
            }
        },
        consultaApiPeru1() {
            var self = this,
                token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
            axios
                .get('https://apiperu.dev/api/' + this.doc_fact.toLowerCase() + '/' + this.num_fact, {
                    headers: {
                        Content_Type: 'application/json',
                        authorization: ' Bearer ' + token
                    }
                })
                .then(response => (this.info = response.data,
                    store.commit("dialogoprogress", 1),
                    self.llenardatos1(response.data.data)
                ))
                .catch(error => {
                    store.commit("dialogoprogress", 1),
                        store.commit('dialogosnackbar', 'Documento Invalido')
                })
        },
        llenardatos1(data) {
            if (this.doc_fact == 'DNI') {
                this.nom_fact = data.nombre_completo
                this.dir_fact = data.direccion_completa
            }
            if (this.doc_fact == 'RUC') {
                this.nom_fact = data.nombre_o_razon_social
                this.dir_fact = data.direccion_completa
            }
        },
        async genera_comprobante(data) {
            if (this.comparafecha()) {
                store.commit("dialogoprogress", 2)
                if (this.nombreCompleto == '') {
                    this.nombreCompleto = 'CLIENTES VARIOS'
                }
                this.documento = "DNI"
                if (this.numero == '') {
                    this.numero = '00000000'
                }

                if (this.documento == "DNI") {
                    var doccliente = "1" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
                }
                if (this.documento == "RUC") {
                    var doccliente = "6" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
                }
                if (this.documento == "Pasaporte") {
                    var doccliente = "7" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
                }
                if (this.documento == "Carnet de Extranjeria") {
                    var doccliente = "4" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
                }
                if (data == "1") {
                    var tipocomprobante = 'T'
                    var cod_comprobante = '00'
                    var correlativo = ''
                    var serie = ''
                    var automata = 'NO'
                    var estado = 'aprobado'
                }
                if (data == "2") { //Catálogo No. 01: Código de Tipo de documento
                    var tipocomprobante = 'B'
                    var cod_comprobante = '03'
                    var correlativo = ''
                    var serie = ''
                    var automata = ''
                    var estado = 'PENDIENTE'
                }
                if (data == "3") { //Catálogo No. 01: Código de Tipo de documento 
                    var tipocomprobante = 'F'
                    var cod_comprobante = '01'
                    var correlativo = ''
                    var serie = ''
                    var automata = ''
                    var estado = 'PENDIENTE'
                }
                var id_receta = ''
                var modo_receta = ''
                if (Boolean(this.data_receta)) {
                    id_receta = this.data_receta.id
                    modo_receta = this.data_receta.modo
                }
                var totalcuenta = this.total
                var fechahoy = this.verdate()

                var array_cabecera = {
                    serie: serie,
                    correlativoDocEmitido: correlativo,
                    numeracion: tipocomprobante + correlativo,
                    tipoDocumento: this.documento,
                    cod_tipoDocumento: doccliente,
                    dni: this.numero,
                    cliente: this.nombreCompleto.toUpperCase(),
                    direccion: this.direccion,
                    observacion: this.observacion.toUpperCase(),
                    tipocomprobante: tipocomprobante,
                    cod_comprobante: cod_comprobante,
                    automata: automata,
                    estado: estado,
                    mensajeSunat: '',
                    hash: '',
                    documentId: '',
                    fecha: fechahoy,
                    vencimientoDoc: fechahoy,
                    modopago: this.modopagos,
                    forma_pago: "Contado",
                    cuotas: '',
                    id_receta: id_receta,
                    modo_receta: modo_receta,
                    vendedor: this.vendedor,
                    id_vendedor: this.obten_vendedor(this.vendedor).codigo,
                    total: totalcuenta,
                    a_cuenta: this.a_cuenta,
                    doc_fact: this.doc_fact,
                    num_fact: this.num_fact,
                    nom_fact: this.nom_fact,
                    dir_fact: this.dir_fact,
                    telf_fact: this.telf_fact,
                    telefono: this.telefono,
                    bd: store.state.baseDatos.bd,
                    tienda: store.state.permisos.tienda,
                }
                if (this.telefono != '') {
                    this.enviar_sms(this.telefono)
                    await edita_campo_Cliente(this.numero, 'telefono', this.telefono)
                }
                await cobrar_js(array_cabecera, this.listaproductos)
                await this.termina_venta(array_cabecera, this.listaproductos)
                this.seleccionado = array_cabecera
                store.commit("dialogoprogress", 2)
                this.genera_pdf = true

            } else {
                store.commit('dialogosnackbar', 'FECHA EXCEDE EL LIMITE PERMITIDO')
            }
            return false
        },
        async enviar_sms(num) {
            var producto = this.listaproductos.filter(item => item.tipo == "P")
            var numero = ''
            if (num.length == 9) {
                numero = '51' + num
            }
            if (num.length == 11) {
                numero = num
            }
            var fech = ''
            var snap = await allcitas().orderByChild('dni').equalTo(this.numero).once('value')
            if (snap.exists()) {
                snap.forEach((item) => {
                    fech = moment.unix(item.val().inicio).format('DD/MM/YY')
                })
            }

            if (producto[0].tipo_focal == 'MONOFOCAL DIGITAL' || producto[0].tipo_focal == 'MULTIFOCAL DIGITAL' || producto[0].tipo_focal == 'MULTIFOCAL CONVENCIONAL') {
                var mensaje = 'Hola✋ Bienvenido a 🤓 Opticenter Vision🤓. Gracias por formar parte de nuestra familia de clientes.🤗 \n \n' +
                    '*Te  enviaremos un  mensaje 📲cuando tus lentes se encuentren listos y puedas recogerlo en tienda.✅ \n \n' +
                    '*Tambien un control agendado 📝 para el día ' + fech + ' en la cual se te hara recordar 2 dias antes, no olvidar acudir con tus lentes.🤓.\n \n' +
                    '*Para cualquier consulta puedes comunicarte directamente con nosotros por este número oficial de Opticenter vision.\n \n' +
                    'Gracias.'
            } else {
                var mensaje = 'Hola✋ Bienvenido a 🤓Opticenter Vision🤓. Gracias por formar parte de nuestra familia de clientes. \n \n' +
                    '*Tienes agendado un control📝 el ' + fech + ' en la cual se te hará recordar 2 dias antes, no olvidar acudir con tus lentes.🤓 \n \n' +
                    '*Para cualquier consulta puedes comunicarte directamente con nosotros por este número oficial de Opticenter vision.\n \n' +
                    'Gracias.'
            }

            if (numero != '') {
                send_sms(mensaje, numero)
            }

        },
        obten_vendedor(nom) {
            var lista = store.state.tabla_empleados.find(item => item.nombre.trim() == nom.trim())
            return lista
        },
        async termina_venta(cabecera, detalle) {
            var arra_comp = []
            var mont = detalle.filter(item => item.tipo == 'M')
            if (mont != '') {
                for (var i = 0; i < mont.length; i++) {
                    await editaMontura(mont[i].id + '/stock', 0)
                }
            }
            if (Boolean(cabecera.id_receta)) {
                for (var i = 0; i < detalle.length; i++) {
                    if (detalle[i].tipo == 'P') {
                        arra_comp.push(detalle[i])
                    }
                    if (detalle[i].id_pos != '') {
                        //   edita_medicion(cabecera.id_receta, 'array_recom/' + detalle[i].id_pos + '/comprado', true)
                    }
                }
                await edita_medicion(cabecera.id_receta, 'array_compra/', arra_comp)
                if (cabecera.modo_receta == 'INTERNA') {
                    await edita_medicion(cabecera.id_receta, 'estado', 'ATENDIDO')
                }
                if (cabecera.modo_receta == 'EXTERNA') {
                    await edita_medicion(cabecera.id_receta, 'estado', 'ATENDIDO')
                }
            }
            if (this.array_proforma != '') {
                editaProformas(this.array_proforma.id + '/estado', 'ACEPTADO')
                editaProformas(this.array_proforma.id + '/fecha_atencion', cabecera.fecha)
            }
            await genera_pedidos(cabecera, detalle)
            return true
        },

        comparafecha() {
            var hoy = moment(String(new Date())).add(-5, 'd').format('YYYY-MM-DD')
            var fecha = moment(String(this.date)).format('YYYY-MM-DD')
            if (moment(fecha).isAfter(hoy)) {
                return true
            } else {
                return false
            }
        },
        verdate() {
            var fecha = moment(String(this.date)) / 1000
            if (moment(String(new Date())).format('YYYY-MM-DD') == moment(String(this.date)).format('YYYY-MM-DD')) {
                fecha = moment().unix()
            }
            return fecha
        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        busca_ico(data) {
            if (data == 'YAPE') {
                return 'yape.png'
            }
            if (data == 'TARJETA') {
                return 'tarjeta.png'
            }
            if (data == 'EFECTIVO') {
                return 'cash.png'
            }
            if (data == 'PLIN') {
                return 'plin.webp'
            }
            if (data == 'TRANSFERENCIA') {
                return 'bank.png'
            }
        },
        limpiacajas() {
            store.commit('array_paciente', [])
            this.numero = ''
            this.nombreCompleto = ''
            this.data_receta = ''
            this.telefono = ''
            this.vendedor = ''
            this.a_cuenta = ''
            this.doc_fact = ''
            this.num_fact = ''
            this.nom_fact = ''
            this.dir_fact = ''
            this.telf_fact = ''
            this.observacion = ''
            this.direccion = ''
            this.lista_venta = []
            this.dial_cobrar = false
        },
        montura_qr(item) {
            store.commit("dialogoprogress", 1)
            this.lista_venta.push({
                uuid: this.create_UUID().substring(29),
                id: item.id,
                nombre: item.marca + ' ' + item.modelo,
                cantidad: item.cantidad,
                precio: item.precio,
                total: item.precio * item.cantidad,
                operacion: item.operacion,
                tipoproducto: item.tipoproducto,
                medida: 'UNIDAD',
                tipo: 'M'
            })
            setTimeout(() => store.commit("dialogoprogress", 1), 500);
            store.commit('abre_qr', false)
        }
    },

}
</script>

<style scoped>
@keyframes anim {
    0% {
        background-color: rgb(255, 255, 255);

    }

    50% {
        background-color: rgb(252, 114, 114);
    }

    100% {
        background-color: rgb(255, 255, 255);
    }
}

.id_card_rect {
    animation-name: anim;
    animation-duration: 5s;
    animation-iteration-count: infinite;

}
</style>
